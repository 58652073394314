import React, { Component } from 'react';
import './OncoSigStyles.css';

import Graph from 'react-graph-vis';

import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import 'react-virtualized/styles.css';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';
import {PrefixIndexStrategy} from 'js-search';

import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';

import Snackbar from '@material-ui/core/Snackbar';

import { CSVLink } from 'react-csv';

import { SUPERVISED_PROTEINS } from './supervisedProteins';

let UNSUPERVISED_FILTER_OPTIONS = null;

class OncoSigHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsupervisedProteins: [],
      selectedProteins: [],
      selectedTissue: "luad",
      selectedSigMapType: "supervised",
      network: null,
      nodes: [],
      relationships: [],
      selectedEdgeIDs: [],
      showLoader: false,
      imageURL: '',
      contactDialogOpen: false,
      aboutDialogOpen: false,
      faqDialogOpen: false,
      helpDialogOpen: false,
    };
  }

  componentDidMount() {
    const fetchProteins = async () => {
      const response = await fetch('http://oncosig.org/api/get_proteins');
      const body = await response.json();
      return body;
    };

    fetchProteins()
      .then(response => {
        const proteins = response.proteins
          .filter(p => !SUPERVISED_PROTEINS.includes(p))
        const options = proteins.map(p => ({value: p, label: p}))
        const indexStrategy = new PrefixIndexStrategy();
        UNSUPERVISED_FILTER_OPTIONS = createFilterOptions({indexStrategy, options});
        this.setState({unsupervisedProteins: proteins});
      });
  }

  _onClickGenGraph = () => {
    if (this.state.selectedProteins.length === 0) {
      this.setState({
        nodes: [],
        relationships: [],
        selectedEdgeIDs: [],
      });
      return;
    }

    this.setState({
      showLoader: true,
      nodes: [],
      relationships: [],
      selectedEdgeIDs: [],
    });

    const fetchGraph = async () => {
      const proteins = (this.state.selectedProteins.map(option => option.value))
        .join(',');

      const url = 'http://oncosig.org/api/get_oncosig_graph?proteins=' + proteins
        + '&tissue=' + this.state.selectedTissue;
      const response = await fetch(url);
      const body = await response.json();

      if (response.status !== 200) {
        throw Error(body.message);
      }
      return body;
    };

    fetchGraph().then(response => {
      if (this.state.network) {
        this.state.network.unselectAll();
      }

      this.setState({
        nodes: response.nodes,
        relationships: response.relationships,
        showLoader:false,
        selectedEdgeIDs: [],
      });
    });
  };

  renderTissueAndSigMapSelect() {
    return (
      <div style={{marginLeft: '8px'}}>
        <FormControl>
          <InputLabel>Tissue (TCGA code)</InputLabel>
          <Select
            style={{width: 170}}
            value={this.state.selectedTissue}
            onChange={e => this.setState({selectedTissue: e.target.value})}
            label="Tissue">
            <MenuItem value="luad">luad</MenuItem>
            <MenuItem value="coad">coad</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel style={{marginLeft: '16px'}}>SigMap Type</InputLabel>
          <Select
            style={{width: 170, marginLeft: '16px'}}
            onChange={e => this.setState({
              selectedSigMapType: e.target.value,
              selectedProteins: [],
            })}
            value={this.state.selectedSigMapType}>
            <MenuItem value="supervised">supervised</MenuItem>
            <MenuItem value="unsupervised">unsupervised</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  renderLegend() {
    return (
      <div>
        <div style={{
          fontWeight: 'bold',
          fontSize: '18px',
          margin: '24px 0px 8px 142px',
        }}>
          Legend
        </div>
        <div>
          <span style={{color: '#f36924', fontWeight: 'bold'}}>Orange Node</span>
          {' - Upstream'}
        </div>
        <div>
          <span style={{color: '#23b3d7', fontWeight: 'bold'}}>Blue Node</span>
          {' - Downstream'}
        </div>
        <div>
          <span style={{color: '#b261a5', fontWeight: 'bold'}}>Purple Node</span>
          {' - Upstream and Downstream'}
        </div>
        <div>
          <span style={{color: '#9AA1AC', fontWeight: 'bold'}}>Gray Node</span>
          {' - Physical'}
        </div>
        <div>
          <span style={{color: '#F79767', fontWeight: 'bold'}}>Thick Orange Edge</span>
          {' - Upstream Physical'}
        </div>
        <div>
          <span style={{color: '#57C7E3', fontWeight: 'bold'}}>Thick Blue Edge</span>
          {' - Downstream Physical'}
        </div>
      </div>
    );
  }

  renderCSVButton() {
    const headers = [
      {label: "From", key: "from"},
      {label: "To", key: "to"},
      {label: "OncoSig", key: "oncosig"},
      {label: "PrePPI_LR", key: "preppi_lr"},
    ];
    const csvData = this.state.relationships.map(r => ({
      from: r.from,
      to: r.to,
      oncosig: r.oncosig,
      preppi_lr: r.preppi_lr,
    }))

    return(
      <CSVLink
        headers={headers}
        data={csvData}
        style={{textDecoration:'none'}}
        filename="OncoSig_interactions">
        <Button
          style={{margin: '8px 0px 0px 63px'}}
          variant="contained"
          disabled={this.state.relationships.length === 0}>
          Download Interactions
        </Button>
      </CSVLink>
    );
  }

  renderDownloadImageButton() {
    return (
      <a
        style={{textDecoration:'none'}}
        download="OncoSig_map.png"
        href={this.state.imageURL}>
        <Button
          style={{margin: '16px 0px 0px 92px'}}
          disabled={this.state.relationships.length === 0}
          variant="contained"
          onClick={() => {
            const canvas = document.getElementsByTagName('canvas')[0];
            const url = canvas.toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            this.setState({imageURL: url});
          }}>
          Download Image
        </Button>
      </a>
    );
  }

  renderIntroButtons() {
    return (
      <ButtonGroup
        size="small"
        variant="text"
        color="primary"
        style={{marginLeft: '72px', marginBottom: '20px'}}>
        <Button
          onClick={() => this.setState({aboutDialogOpen: true})}>
          About
        </Button>
        <Button
          onClick={() => this.setState({contactDialogOpen: true})}>
          Contact
        </Button>
        <Button onClick={() => this.setState({faqDialogOpen: true})}>
          FAQ
        </Button>
        <Button onClick={() => this.setState({helpDialogOpen: true})}>
          Help
        </Button>
      </ButtonGroup>
    );
  }

  renderAboutDialog() {
    return (
      <Dialog
        open={this.state.aboutDialogOpen}
        onClose={() => this.setState({aboutDialogOpen: false})}>
        <DialogTitle style={{fontWeight: 'bold'}}>{'Welcome to OncoSig!'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'OncoSig predicts the context-specific architecture of oncoproteins. A protein’s SigMap comprises its modulators, effectors, and cognate binding-partners and is systematically reconstructed, de-novo, by integrating multiple evidence sources, including protein structure, gene expression, mutational data, and protein activity.'}
            <div style={{marginTop: '26px'}}>
              {'OncoSig is developed at Columbia University, in the Department of Systems Biology, in the labs of '}
              <Link href="http://califano.c2b2.columbia.edu/" target="_blank">Andrea Califano</Link>
              {' and '}
              <Link href="http://honig.c2b2.columbia.edu/" target="_blank">Barry Honig</Link>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({aboutDialogOpen: false})}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderContactDialog() {
    return (
      <Dialog
        open={this.state.contactDialogOpen}
        onClose={() => this.setState({contactDialogOpen: false})}>
        <DialogTitle>{'Contact'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Contact the OncoSig team by email at '}
            <Link href="mailto:st3179@cumc.columbia.edu">
              st3179@cumc.columbia.edu
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({contactDialogOpen: false})}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderFAQDialog() {
    return (
      <Dialog
        fullScreen
        open={this.state.faqDialogOpen}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.setState({faqDialogOpen: false})}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">FAQ</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{position: 'relative', top: '64px', padding: '16px 32px'}}>
          <div style={{fontWeight: 'bold', fontSize: '22px', marginBottom: '16px'}}>What is OncoSig?</div>
          <div>
            {'OncoSig presents an integrative Machine Learning (ML) framework for the systematic, de novo reconstruction of tumor-specific molecular interaction signaling maps (SigMaps), anchored on any oncoprotein of interest. OncoSig infers context-specific SigMaps by training an ML algorithm to integrate complementary evidence sources from transcriptional and post-transcriptional interactions inferred from gene expression and mutational profiles in large-scale repositories, such as The Cancer Genome Atlas (TCGA), and structure-based protein-protein interactions.'}
          </div>
          <div style={{fontWeight: 'bold', fontSize: '22px', marginTop: '16px'}}>What information can OncoSig provide?</div>
          <ul>
            <li>{'OncoSig provides an unbiased, compact, and realistic representation of a protein’s mechanism of action.'}</li>
            <li>{'Based on a wealth of experimental assays - both novel and previously published - OncoSig effectively recapitulates the complex biology of signal transduction, with a high validation rate for novel predictions.'}</li>
            <li>{'OncoSig effectively discriminates between upstream modulators and downstream effectors.'}</li>
            <li>{'OncoSig provides hypotheses regarding autoregulatory interactions (loops) which are critical to ensure the stability of cellular phenotypes and may be responsible for complex adaptive behavior, such as in response to pharmacological perturbations.'}</li>
          </ul>
          <div style={{fontWeight: 'bold', fontSize: '22px', marginBottom: '16px'}}>How does the OncoSig work?</div>
          <div>
            {'The OncoSig feature matrix for a protein of interest consists only of those features that correspond to interactions with the protein of interest and, thus, has only four columns, one for each of the four interactomes: PrePPI, ARACNe, CINDy, and VIPER. OncoSig membership of each protein in the human proteome is determined by aggregate voting after querying the ten OncoSig'}
            <sup style={{verticalAligh: 'top', fontSize: '0.6em'}}>RF</sup>
            {' classifiers (KRAS, PI3KCA, TP53, EGFR, BRAF, STK11, CDKN2A, NTRK3, YAP1, and CTNNB1).'}
          </div>
          <div style={{fontWeight: 'bold', fontSize: '22px', margin: '16px 0px'}}>Where can we download Oncosig data?</div>
            {'The interaction networks of the protein of interest could be downloaded in the form of:'}
            <ul>
              <li>An image file (.png)</li>
              <li>A network file (.csv)</li>
            </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({faqDialogOpen: false})}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderHelpDialog() {
    return (
      <Dialog
        fullScreen
        open={this.state.helpDialogOpen}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.setState({helpDialogOpen: false})}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Help</Typography>
          </Toolbar>
         </AppBar>
        <DialogContent style={{position: 'relative', top: '64px', padding: '16px 32px'}}>
          <div style={{fontWeight: 'bold', fontSize: '22px', marginBottom: '16px'}}>OncoSig Tissue Categories</div>
          <div>
            {'Currently, OncoSig supports two tissue categories:'}
            <ul>
              <li>Lung adenocarcinoma (LUAD)</li>
              <li>Colon adenocarcinoma (COAD)</li>
            </ul>
          </div>
          <div style={{fontWeight: 'bold', fontSize: '22px', margin: '16px 0px'}}>OncoSig Sigmap types</div>
          <div>
            {'Currently, OncoSig supports two SigMap categories:'}
            <ul>
              <li>Supervised</li>
              <li>Unsupervised</li>
            </ul>
          </div>
          <div style={{fontWeight: 'bold', fontSize: '22px', margin: '16px 0px'}}>OncoSig search tips</div>
          <div>
            A simple search in OncoSig can be done by selecting the tissue (LUAD or COAD), SigMap type (supervised or unsupervised) and providing the oncoprotein name in the search box. If supervised SigMap option is selected, users have a choice of selecting 10 oncoproteins, namely, KRAS, PI3KCA, TP53, EGFR, BRAF, STK11, CDKN2A, NTRK3, YAP1, and CTNNB1, respectively. If unsupervised SigMap option is selected, users have a choice of selecting any oncoprotein of interest. Clicking the ‘Generate Graph’ button displays the OncoSig network graph.
          </div>
          <div style={{fontWeight: 'bold', fontSize: '22px', margin: '16px 0px'}}>OncoSig graph</div>
          <div>The OncoSig network graph has 6 elements:</div>
          <ul>
            <li><span style={{color: '#f36924'}}>Orange Node</span> - Upstream</li>
            <li><span style={{color: '#23b3d7'}}>Blue Node</span> - Downstream</li>
            <li><span style={{color: '#b261a5'}}>Purple Node</span> - Upstream and Downstream</li>
            <li><span style={{color: '#9AA1AC'}}>Gray Node</span> - Physical</li>
            <li><span style={{color: '#F79767'}}>Thick Orange Edge</span> - Upstream Physical</li>
            <li><span style={{color: '#57C7E3'}}>Thick Blue Edge</span> - Downstream Physical</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({helpDialogOpen: false})}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderSelectorWindow() {
    return (
      <Paper className="OncoSigSelectorWindow">
        <div
          style={{
            marginLeft: '88px',
            marginBottom: '2px',
            fontSize: '22px',
            fontWeight: 'bold',
          }}>
          OncoSig Explorer
        </div>
        {this.renderIntroButtons()}
        {this.renderTissueAndSigMapSelect()}
        <div style={{marginTop: '24px', padding: '0px 4px'}}>
          {this.renderSelectProteinsBar()}
        </div>
        <Button
          style={{marginTop: '24px', marginLeft: '92px'}}
          variant="contained"
          onClick={this._onClickGenGraph}>
          {'Generate Graph'}
        </Button>
        {this.renderDownloadImageButton()}
        {this.renderCSVButton()}
        {this.renderLegend()}
      </Paper>
    );
  }

  renderSelectProteinsBar() {
    let options = null;
    let filterOptions = null;
    if (this.state.selectedSigMapType === 'supervised') {
      options = SUPERVISED_PROTEINS.sort()
        .map(protein => ({value:protein, label: protein}));
      const indexStrategy = new PrefixIndexStrategy();
      filterOptions = createFilterOptions({indexStrategy, options});
    } else {
      options = this.state.unsupervisedProteins
        .map(protein => ({value:protein, label: protein}));
      filterOptions = UNSUPERVISED_FILTER_OPTIONS
    }

    return (
      <VirtualizedSelect
        placeholder="Select Protein(s)"
        options={options}
        multi={true}
        onChange={(selected) => this.setState({selectedProteins: selected})}
        value={this.state.selectedProteins}
        filterOptions={filterOptions}
      />
    );
  }

  renderLoader() {
    if (!this.state.showLoader) {
      return;
    }
    return (
      <CircularProgress style={{color: "#03a9f4"}} className="Loader" size={50}/>
    );
  }

  renderGraph() {
    const graph = {
      nodes: this.state.nodes,
      edges: this.state.relationships,
    };

    const options = {
      height: '100%',
      width: '100%',
      autoResize: true,
      layout: {
        hierarchical: {levelSeparation: 75, treeSpacing: 1, nodeSpacing: 50},
      },
      nodes: {
        shape: 'circle',
        borderWidth: 2,
        widthConstraint: {minimum: 45, maximum: 45},
        font: {
          color: '#FFFFFF',
          size: 11,
          face: '"Helvetica Neue"',
        },
      },
      groups: {
        QueryProteins: {
          color: {background: '#ECB5C9', border: '#da7298'},
          widthConstraint: {minimum: 60, maximum: 60},
        },
        Upstream: {color: {background: '#F79767', border: '#f36924'}},
        Downstream: {color: {background: '#57C7E3', border: '#23b3d7'}},
        Bidirectional: {color: {background: '#C990C0', border: '#b261a5'}},
        Physical: {color: {background: '#A5ABB6', border: '#9AA1AC'}},
      },
      interaction: {
        selectConnectedEdges: true,
        zoomView: true,
      },
      physics: {
        hierarchicalRepulsion: {
          nodeDistance: 90,
          centralGravity: 0.5,
        },
      },
    };

    const events = {
      select: e => {
        if (e.edges.length > 0 && e.edges.length < 8) {
          this.setState({selectedEdgeIDs: e.edges});
        }
      },
      click: e => {
        if (this.state.selectedEdgeIDs.length !== 0 && (e.edges.length === 0 ||
          e.edges.length > 7)) {
          this.setState({selectedEdgeIDs: []});
        }
      },
      dragStart: e => {
        if (e.edges.length > 0 && e.edges.length < 8) {
          this.setState({selectedEdgeIDs: e.edges});
        }
        if (this.state.selectedEdgeIDs.length !== 0 && (e.edges.length === 0 ||
          e.edges.length > 7)) {
          this.setState({selectedEdgeIDs: []});
        }
      },
    };

    return (
      <Graph
        graph={graph}
        options={options}
        events={events}
        getNetwork={network => this.setState({network: network})}
      />
    );
  }

  renderSelectedEdgeData() {
    let message  = '';
    if (this.state.selectedEdgeIDs.length > 0) {
      const edges = this.state.relationships.filter(
        r => this.state.selectedEdgeIDs.includes(r.id),
      );

      const rows = edges.map(edge => {
        let oncosig = null;
        if (edge.oncosig) {
          oncosig = (
            <span style={{marginLeft: '12px'}}>
              <b>OncoSig</b>{': ' + edge.oncosig}
            </span>
          );
        }

        let preppi = null;
        if (edge.preppi_lr) {
          preppi = (
            <span style={{marginLeft: '12px'}}>
              <b>PrePPI_LR</b>{': ' + edge.preppi_lr}
            </span>
          );
        }
        return (
          <div key={edge.id}>
            <span><b>From</b>{': ' + edge.from}</span>
            <span style={{marginLeft: '12px'}}><b>To</b>{': ' + edge.to}</span>
            {oncosig}
            {preppi}
          </div>
        );
      });

      message = (<span>{rows}</span>);
    }

    return (
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        open={this.state.selectedEdgeIDs.length > 0}
        message={message}
      />
    );
  }

  render() {
    return (
      <div className="OncoSigHome">
        {this.renderGraph()}
	{this.renderLoader()}
        {this.renderSelectorWindow()}
        {this.renderSelectedEdgeData()}
        {this.renderContactDialog()}
        {this.renderAboutDialog()}
        {this.renderFAQDialog()}
        {this.renderHelpDialog()}
      </div>
    );
  }
}

export default OncoSigHome;
