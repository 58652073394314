export const SUPERVISED_PROTEINS = [
  'BRAF',
  'CDKN2A',
  'CTNNB1',
  'EGFR',
  'KRAS',
  'NTRK3',
  'PIK3CA',
  'STK11',
  'TP53',
  'YAP1',
];
